import "./../styles/PBP.scss";
import logo from  "./../assets/pixel-logo.svg";

import { RiArrowUpWideFill } from "react-icons/ri";

const PBP = ({onClick, arrow = true}) => {

    return (
        <>

            <div id="pbp" className="pbp" onClick={onClick}>
            {arrow && 
           <div className="icon-container">
            <RiArrowUpWideFill className="icon-arrow" />
            </div>
            
            }
                <div className="branding">
                <div className="poweredby">
                    Powered by
                </div>
                <img src={logo}/>
                </div>
                
            </div>

        </>
    )
    
}

export default PBP;