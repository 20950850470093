

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Header from "../components/Header";
import "./../styles/HomePage.scss";
import PBP from "../components/PBP";

import { ReactComponent as MapIcon } from "./../assets/map-icon.svg"
import { ReactComponent as ModelIcon } from "./../assets/model-icon.svg"
import { ReactComponent as TourIcon } from "./../assets/tour-icon.svg"
import { ReactComponent as SelfieIcon } from "./../assets/selfie-icon.svg"
import { ReactComponent as SettingsIcon } from "./../assets/settings-icon.svg"
import { ReactComponent as NearMeIcon } from "./../assets/near-me-icon.svg"
import { ReactComponent as InfoIcon } from "./../assets/info-icon.svg"
import { ReactComponent as HowToIcon } from "./../assets/how-to-icon.svg"
import { ReactComponent as HuntIcon } from "./../assets/hunt-icon.svg"
import { ReactComponent as FeedbackIcon } from "./../assets/feedback-icon.svg"

import { IoSettingsOutline } from "react-icons/io5";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlineFeedback } from "react-icons/md";
import ScanPage from "./ScanPage";

const HomePage = () => {
    const spaces = useSelector((state) => state.tours.tours.spaces);

    return (
        <>
            <div id="splash-container-background" className="app-container"></div>
            <div id="HomeContainer" className="app-container">
                <Header />
                <div className="link-container">
                    <div className="links">
                        <Link className="link" to={'/map'}>
                            <MapIcon className="icon icon-home" />
                            <span>Tour Map</span>
                        </Link>
                        <Link className="link" to={'/model'}>
                            <ModelIcon className="icon icon-home" />
                            Model
                        </Link>
                        {/* <Link className="link" to={`${window.location.origin}/tour/${spaces[0].space_id}`}>
                            <TourIcon className="icon icon-home" />
                            Tour
                        </Link> */}
                        <Link className="link" to={`/scan`}>
                            <TourIcon className="icon icon-home" />
                            Tour
                        </Link>
                        <Link className="link">
                            <SelfieIcon className="icon icon-home icon-fill" />
                            Selfie
                        </Link>
                        <Link className="link" to={"/settings"}>
                            <SettingsIcon className="icon icon-home icon-fill" />
                            Settings
                        </Link>
                        <Link className="link">
                            <NearMeIcon className="icon icon-home" />
                            Near Me
                        </Link>
                        <Link className="link">
                            <InfoIcon className="icon icon-home" />
                            Info
                        </Link>
                        <Link className="link">
                            <HowToIcon className="icon icon-home" />
                            How To
                        </Link>
                    </div>
                    <div className="hunt-container">
                    Want to play a game while you explore?
                    <Link to={"/hunt"}>
                    <HuntIcon className="icon-hunt"/>
                    </Link>
                    </div>
                </div>
                <div className="footer">
                    <div className="col-left col">
                        {/* <Link className="link">
                            <IoSettingsOutline className="icon icon-footer" />
                            <span>SETTINGS</span>
                        </Link>
                        <Link className="link" >
                            <IoIosInformationCircleOutline className="icon icon-footer" />
                            <span>INFO</span>
                        </Link> */}
                    </div>
                    <div className="col-right col">
                        <Link className="link">
                            <FeedbackIcon className="icon-footer" />
                            <span>FEEDBACK</span>
                        </Link>
                    </div>
                </div>
            </div>
            <PBP arrow={false}/>
        </>
    )

}

export default HomePage;