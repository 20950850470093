import {createBrowserRouter, Navigate} from "react-router-dom";
import HomePage from "./containers/HomePage";
import NotFound from "./containers/NotFound";
import ScanPage from "./containers/ScanPage";
import TourPage from "./containers/TourPage";
import MapPage from "./containers/MapPage";
import ModelPage from "./containers/ModelPage";
import SplashPage from "./containers/SplashPage";
import SettingsPage from "./containers/SettingsPage";
import HuntPage from "./containers/HuntPage";

const router = createBrowserRouter([
    {
      path: '/',
      element: <SplashPage/>,
    },
    {
      path: '/home',
      element: <HomePage/>,
    },
    {
      path: '/scan',
      element: <ScanPage/>,
    },
    {
      path: '/tour/:space_id',
      element: <TourPage/>,
    },
    {
      path: '/map',
      element: <MapPage/>,
    },
    {
      path: '/settings',
      element: <SettingsPage/>,
    },
    {
      path: '/hunt',
      element: <HuntPage/>,
    },
    {
      path: '/model',
      element: <ModelPage/>,
    },   
    {
      path: "*",
      element: <NotFound/>
    }
  ])
  
  export default router;