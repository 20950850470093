import Header from "../components/Header";
import "./../styles/ModelPage.scss";

const ModelPage = () => {
    return (
        <>
        <div id="model-container" className="app-container">
            <Header  close={true}/>
        </div>
        </>
    )
}

export default ModelPage;