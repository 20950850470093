import Header from "../components/Header";
import "./../styles/SettingsPage.scss";

const SettingsPage = () => {
    return (
        <>
        <div id="settings-container" className="app-container">
            <Header  close={true}/>
        </div>
        </>
    )
}

export default SettingsPage;