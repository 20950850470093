import "./../styles/Header.scss";


import { ReactComponent as HomeIcon } from "./../assets/home-icon.svg"
import { ReactComponent as CloseIcon } from "./../assets/close-icon.svg"

import { useNavigate } from "react-router-dom";

const Header = ({title, className, close=false}) => {

    const navigate = useNavigate();

   const onHome = () => {
        navigate(`/home`);
   }

const onClose = () => {
    window.history.back();
}

    return (
        <div className={`header ${className}`}>
            <div className="row">
                <div className="app-title">
                    Jaigarh Fort
                </div>
                <HomeIcon className=" icon-header" onClick={onHome}/>
                
            </div>
            {title && <div className="header-title">
                {title}
            </div>}

            {close && <CloseIcon className=" icon-header icon-close" onClick={onClose}/>}

        </div>
    )
}

export default Header;