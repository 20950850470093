import "./../styles/Sidebar.scss";
import {ReactComponent as MapIcon} from "./../assets/map-icon.svg";
import {ReactComponent as HowToIcon} from "./../assets/how-to-icon.svg";
import {ReactComponent as SettingsIcon} from "./../assets/settings-icon.svg";


import { Link, useNavigate } from "react-router-dom";

const Sidebar = ({children}) => {

    const navigate = useNavigate();

return(
    <>
    <div className="sidebar">
        <Link to={'/map'}>
        <MapIcon className=" icon-sidebar stroke" />
        </Link>
        <Link>
        <HowToIcon className=" icon-sidebar stroke"/>
        </Link>
        
        <Link to={'/settings'}>
        <SettingsIcon className=" icon-sidebar fill"/>
        </Link>
        
        {children}
    </div>
    </>
)
}

export default Sidebar;