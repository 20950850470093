import Header from "../components/Header";
import "./../styles/HuntPage.scss";

const HuntPage = () => {
    return (
        <>
        <div id="hunt-container" className="app-container">
            <Header close={true}/>
        </div>
        </>
    )
}

export default HuntPage;