import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import PBP from "../components/PBP";
import "./../styles/SplashPage.scss";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';

const SplashPage = () => {

    const spaces = useSelector((state) => state.tours.tours.spaces);
    const [totalImages, setTotalImages] = useState(0);
    const [loadedImages, setLoadedImages] = useState(0);

    const navigate = useNavigate();


    useEffect(() => {

        const imagestack = [];

        spaces.forEach(space => {
            imagestack.push(space.space_image);

            if(space.space_history && space.space_history.length){
                space.space_history.forEach(era => {
                    imagestack.push(era.asset_url);
                })
            }
        })

       // console.log(imagestack);
       setTotalImages(imagestack.length);
        loadImage(imagestack, 0);

    }, [])


    const loadImage = (imgs, index) => {
        if(imgs.length > index){
            console.log(imgs[index]);
            let img = new Image();
            img.onload = () => {
                loadImage(imgs, index + 1);
                setLoadedImages(index + 1);
            }
            img.src = `${process.env.REACT_APP_ASSET_URL}/${imgs[index]}`;
        }else{
            onClickedHome();
        }
    }

    const onClickedHome = () => {
        console.log("clicked");
        navigate('/home');
    }


    return (
        <>
        <div id="splash-container-background" className="app-container">

        </div>
        <div id="splash-container" className="app-container">
            
            <div className="splash-text">
                <div className="titles">
                    <div className="title-one">
                        Welcome to
                    </div>
                    <div className="title-two">
                        Jaigarh Fort
                    </div>
                </div>
                <div className="explore-text">
                    Explore the Fort through the years...
                </div>
                
            </div>


        </div>
        <div className="loader-container">
            <CircularProgress sx={{color:'#353737'}}/>
            <div className="loading-text">
                Caching images... Please wait...
            </div>
            <div className="loading-text">
                {loadedImages + '/' + totalImages}
            </div>
        </div>
        <PBP onClick={()=>{}}/>
        </>
    )
}


export default SplashPage;