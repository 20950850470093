
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { loadTours } from './redux-store/actions/tourActions';

import router from './router';


const App = () => {

    const tours = useSelector((state) => state.tours);
    const dispatch = useDispatch();
    const [toursLoaded, setToursLoaded] = useState(false);

    const fetchTours = async () => {
        const response = await axios
            .get(`${process.env.REACT_APP_API_URL}/space`)
            .catch((err) => {
                console.log(err);
            })
        dispatch(loadTours(response.data));
        setToursLoaded(true);
    }

    useEffect(() => {
        fetchTours();
    }, []);
    return (
        <>
            { toursLoaded && <RouterProvider router={router} /> }
        </>
    )
}

export default App;